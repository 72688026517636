<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Fenômenos Vasculares" icon="bubble_chart" bold />
    <div v-if="hasVascularPhenomena">
      <v-list two-line>
        <v-list-group
          v-for="(vp, indexVp) in diagnose.vascularPhenomena"
          :key="indexVp"
          class="elevation-1"
          @click="selectIndex(indexVp)"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ vp.vascularPhenomenon.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Zona de resolução:
                  {{ vp.diagnoseResolutionZone.resolutionZone.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div>
            <view-tracking-impaction-point
              class="grey"
              :tracking-point="
                vp.diagnoseResolutionZone.resolutionZone.trackingPoint
              "
              :impaction-point="
                vp.diagnoseResolutionZone.resolutionZone.impactionPoint
              "
            />
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="vp.diagnoseResolutionZone.affectedSpotParTrauma"
                label="Ponto afetado - Par Trauma"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
            </v-row>
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="vp.therapistComments"
                label="Comentários do terapeuta (não será exibido para o paciente)"
                return-value="newValue"
                @save="changeProperties"
              />
            </v-row>
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="vp.patientComments"
                label="Comentários
              ao paciente"
                return-value="newValue"
                @save="changeProperties"
              />
            </v-row>
            <v-row justify="center" class="py-5">
              <v-btn
                dark
                color="red"
                @click="removeVascularPhenomenon(indexVp)"
              >
                <v-icon class="mr-2">delete</v-icon>
                Remover
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum fênomeno vascular adicionado.</div>
  </div>
</template>

<script>
export default {
  components: {
    TextWithEditor: () => import("@/components/shared/TextWithEditor"),
  },

  data: () => ({
    selectedIndex: -1,
  }),
  computed: {
    hasVascularPhenomena() {
      return this.$store.getters["diagnosis/hasVascularPhenomena"];
    },
    diagnose() {
      return this.$store.getters["diagnosis/diagnose"];
    },
  },
  methods: {
    removeVascularPhenomenon(index) {
      const ok = confirm(
        "Tem certeza que deseja remover este FENÔMENO VASCULAR do diagnóstico?"
      );
      if (ok) this.$store.commit("diagnosis/removeVascularPhenomenon", index);
    },
    changeProperties(value) {
      this.$store.commit("diagnosis/changePropertyVascularPhenomena", {
        index: this.indexSelected,
        property: value,
      });
    },
    changeAffectedSpotParTrauma({ newValue }) {
      this.$store.commit(
        "diagnosis/changeAffectedSpotParTraumaVascularPhenomenon",
        {
          index: this.selectedIndex,
          newValue,
        }
      );
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style></style>
